.contentBlock {
  .contentBlockContainer {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
  
    &.isGroupStart {
      padding-bottom: 60px;
    }
  
    &.isGroupEnd {
      padding-top: 60px;
    }

    &.noImage {
      align-items: flex-start;
    }

    &.isVCentered {
      align-items: center;
    }

    .contentBlockLeft {
      flex: 1;

      &.isImage,
      &.contentBlockCustomWidth {
        flex: none;
        width: 50%;
      }

      .contentBlockText {
        padding-right: 90px;
      }

      &.isSingle {
        .contentBlockText {
          padding-right: 0;
        }  
      }
    }

    .contentBlockRight {
      flex: 1;

      &.isImage,
      &.contentBlockCustomWidth {
        flex: none;
        width: 50%;
      }

      .contentBlockText {
        padding-left: 90px;
      }

      &.isSingle {
        .contentBlockText {
          padding-left: 0;
        }  
      }
    }

    .contentBlockImage {
      border-top-left-radius: 45px;
      border-bottom-right-radius: 45px;
      overflow: hidden;

      &.contentBlockImageNoRadius {
        border-radius: 0;
      }

      > span {
        display: block !important;
      }
    }

    .contentBlockText {
      .contentBlockTextTitle {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 1em;
        margin-top: 0;
        line-height: 44px;

        &.contentBlockTextTitleLarge {
          font-size: max(34px, 2.75vw);
          line-height: calc(max(34px, 2.75vw) + 12px);
        }
      }

      .contentBlockTextDescription {
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        color: #D11A6D;
        margin-bottom: 22px;
      }

      .contentBlockTextContent {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;

        > a, 
        > strong,
        > p > a,
        > p > strong,
        > h2 > a,
        > h2 > strong,
        > h3 > a,
        > h3 > strong,
        > ul > li > a,
        > ul > li > strong {
          color: #D11A6D;
          font-weight: 600;
        }

        > a,
        > p > a,
        > h2 > a,
        > h3 > a,
        > ul > li > a {
          text-decoration: underline;
        }

        > p {
          margin-bottom: 0 !important;
          color: #3f3f3f;
        }

        > ul > li,
        > p > ul > li {
          color: #3f3f3f;
        }
      }
    }

    .contentBlockTextMore {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 45px;

      div {
        font-size: 16px;
        font-weight: 600;
        margin-right: 15px;
      }

      svg {
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
}

@media (max-width: 1700px) {
  .contentBlock {
    .contentBlockLeft.contentBlockDefautlWidth {
      width: 50% !important;
    }

    .contentBlockRight.contentBlockDefautlWidth {
      width: 50% !important;
    }
  }
}

@media (max-width: 1200px) {
  .contentBlock {
    .contentBlockContainer {
      flex-direction: column !important;
      padding-top: 100px;
      padding-bottom: 100px;
  
      &.isGroupStart {
        padding-bottom: 50px;
      }
    
      &.isGroupEnd {
        padding-top: 50px;
      }

      .contentBlockLeft {
        width: 100% !important;
      }

      .contentBlockLeft:not(.isSingle) {
        .contentBlockText, 
        .contentBlockImage {
          padding: 0 !important;
          margin: 0 0 60px 0 !important;
        }
      }

      .contentBlockRight {
        width: 100% !important;
        
        .contentBlockText, 
        .contentBlockImage {
          padding: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 1370px) {
  .contentBlock {
    .contentBlockContainer {
      .contentBlockHideTablet {
        display: none !important;
      }

      .contentBlockLeft:has(+ .contentBlockHideTablet) {
        width: 100% !important;

        .contentBlockText {
          margin-bottom: 0 !important;
          padding-right: 0 !important;
        }
      }

      .contentBlockHideTablet + .contentBlockRight {
        width: 100% !important;

        .contentBlockText {
          margin-bottom: 0 !important;
          padding-left: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .contentBlock {
    .contentBlockContainer {
      &.contentBlockFirst {
        padding-top: 50px !important;
      }

      .contentBlockHideMobile {
        display: none !important;
      }

      .contentBlockLeft + .contentBlockHideMobile {
        .contentBlockText {
          margin-bottom: 0 !important;
        }
      }

      .contentBlockHideMobile + .contentBlockRight {
        .contentBlockText {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}